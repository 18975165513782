import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import styled from "styled-components";
import { PauseIcon, PlayIcon } from "@radix-ui/react-icons";

type Props = {
  audio: string;
  title: string;
};

const Waveform = ({ audio, title }: Props) => {
  const containerRef = useRef<HTMLElement>();
  const waveSurferRef = useRef<WaveSurfer>();
  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current as any,
      // responsive: true,
      barWidth: 2,
      barHeight: 2,
      cursorWidth: 0,
      waveColor: "#46494C",
      progressColor: "#FFFFFF",
      height: 40,
    });
    waveSurfer.load(audio);
    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          color: "white",
          fontSize: 13,
          fontWeight: 400,
          textAlign: "left",
          marginLeft: 72,
        }}
      >
        {title}
      </div>
      <Container>
        <PlayButton
          onClick={() => {
            waveSurferRef.current?.playPause();
            setPlaying(waveSurferRef.current?.isPlaying() || false);
          }}
          type="button"
        >
          {isPlaying ? (
            <span className="material-symbols-rounded" style={{ fontSize: 24 }}>
              pause
            </span>
          ) : (
            <span className="material-symbols-rounded" style={{ fontSize: 24 }}>
              play_arrow
            </span>
          )}
        </PlayButton>
        <div ref={containerRef as any} style={{ flex: 1 }} />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const PlayButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 48px;
  height: 48px;
  background: white;
  transition: transform 0.3s ease;
  cursor: pointer;
  :hover {
    transform: scale(1.1); /* Increase size by 10% on hover */
  }
  :active {
    transform: scale(0.9); /* Decrease size by 10% on click */
  }
`;

export default Waveform;
