export const WEBSERVER_URL = process.env.REACT_APP_STUDIO_API_URL || "";

export const CHARACTER_LIMIT = 100;

export const SPEECH_FUN_FACTS = [
  "Honey never spoils; archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still edible.",
  "The Eiffel Tower can be 15 cm taller during the summer due to thermal expansion of the metal.",
  "There are more stars in the universe than grains of sand on all the Earth's beaches.",
  "A leap year happens every four years, but a century leap year only happens if the year is divisible by 400.",
  "The shortest war in history was between Britain and Zanzibar on August 27, 1896. Zanzibar surrendered after 38 minutes.",
  "The longest English word is 189,819 letters long and takes over three hours to pronounce.",
  "There's a species of jellyfish that is biologically immortal.",
  "The inventor of the Pringles can is now buried in one.",
  "A bolt of lightning contains enough energy to toast 100,000 slices of bread.",
  "The Mona Lisa has no eyebrows because it was the fashion in Renaissance Florence to shave them off.",
  "If you could fold a piece of paper 42 times, it would reach the moon.",
  "Sea otters hold hands when they sleep to keep from drifting apart.",
  "Sloths can hold their breath longer than dolphins can.",
  "The human nose can detect over 1 trillion different scents.",
  "An apple, potato, and onion all taste the same if you eat them with your nose plugged.",
  "There are more public libraries in the U.S. than McDonald’s restaurants.",
  "The cigarette lighter was invented before the match.",
  "The inventor of the Frisbee was turned into a Frisbee after he died.",
  "Tigers have striped skin, not just striped fur.",
  "There are more trees on Earth than stars in the Milky Way galaxy.",
  "Mosquitoes are the deadliest animals in the world, responsible for more deaths than any other creature.",
  "Cows have best friends and get stressed when they are separated.",
  "A small child could swim through the veins of a blue whale.",
  "The word 'nerd' was first coined by Dr. Seuss in 'If I Ran the Zoo'.",
  "Cleopatra lived closer in time to the moon landing than to the construction of the Great Pyramid of Giza.",
  "The shortest complete sentence in the English language is 'I am.'",
  "The average person will spend six months of their life waiting for red lights to turn green.",
  "A single strand of spaghetti is called a 'spaghetto'.",
  "In Switzerland, it is illegal to own just one guinea pig because they are social animals.",
  "The inventor of the microwave oven received only $2 for his discovery.",
  "Humans are born with about 300 bones, but adults have only 206.",
  "The first email was sent by Ray Tomlinson to himself in 1971.",
  "A bolt of lightning is five times hotter than the surface of the sun.",
  "A blob of toothpaste on a toothbrush is called a 'nurdle'.",
  "The largest snowflake on record measured 15 inches wide.",
  "There's a basketball court on the top floor of the U.S. Supreme Court building; it's known as the 'Highest Court in the Land'.",
];

export const SPEECH_ADS = [
  "Never lose your keys again with the KeyFinder Pro. Attach, track, and locate with ease.",
  "Want a brighter smile? Try the ShineBright whitening kit for dazzling teeth in just a few days.",
  "Protect your home with the SafeHome security system. Peace of mind at your fingertips.",
  "Want to learn a new language? The LinguaMaster app makes it fun and easy to speak like a native.",
  "Upgrade your sleep with the CozyCloud mattress. Unmatched comfort for the best sleep of your life.",
  "Streamline your cooking with the SmartChef app. Recipes, timers, and grocery lists all in one place.",
  "Boost your confidence with the PerfectBrow kit. Flawless brows in minutes, every time.",
  "Elevate your photography with the SnapPro lens. Capture stunning, high-quality images with ease.",
  "Stay hydrated with the AquaPure water bottle. Built-in filter for fresh, clean water anywhere.",
  "Organize your life with the PlanIt planner. Stay on top of your goals and tasks effortlessly.",
  "Transform your commute with the E-Ride electric scooter. Fast, fun, and eco-friendly transportation.",
  "Pamper yourself with the SpaGlow bath set. Luxurious products for a relaxing spa experience at home.",
  "Unwind with the ChillWave sound machine. Soothing sounds for a peaceful, restful sleep.",
  "Make meal prep a breeze with the SliceSmart kitchen tool. Perfect cuts and slices every time.",
  "Upgrade your tech with the HyperCharge portable charger. Fast, reliable power on the go.",
  "Feel confident in your smile with the FreshBreath mouthwash. Long-lasting freshness and protection.",
  "Stay cool and comfortable with the ChillPad cooling mat. Perfect for hot summer days.",
  "Enhance your yoga practice with the FlexiMat. Non-slip, cushioned, and perfect for all levels.",
  "Protect your skin with the SunShield sunscreen. Broad-spectrum protection for all-day defense.",
  "Transform your garden with the BloomBoost fertilizer. Lush, vibrant plants and flowers with ease.",
  "Stay organized with the DeskMaster organizer. Keep your workspace neat and efficient.",
  "Improve your posture with the AlignPro brace. Comfortable support for better alignment.",
  "Keep your pets healthy with the NutriPets supplements. Tailored nutrition for a happy, active pet.",
  "Elevate your cooking with the FlavorFusion spices. Gourmet blends for delicious meals.",
  "Unwind with the RelaxPro massage chair. Deep tissue relief and ultimate comfort at home.",
  "Stay stylish and warm with the CozyWrap scarf. Fashion meets function in every season.",
  "Stay connected with the ClearCall Bluetooth headset. Crystal clear calls and hands-free convenience.",
  "Upgrade your skincare with the PureGlow cleanser. Gentle, effective, and perfect for all skin types.",
  "Protect your eyes with the VisionGuard glasses. Stylish frames with blue light protection.",
  "Boost your productivity with the TaskMaster app. Organize, prioritize, and conquer your to-do list.",
  "Stay fresh all day with the CoolBreeze deodorant. Powerful protection without the harsh chemicals.",
  "Transform your car with the ShinePro detailing kit. Professional-grade results in your own driveway.",
  "Keep your devices charged with the PowerBank Pro. High-capacity, portable, and reliable.",
  "Elevate your sound with the AudioMaster speakers. Crystal clear audio for any occasion.",
  "Stay fit with the HomeGym app. Personalized workouts and progress tracking in your pocket.",
  "Protect your tech with the SafeCase. Durable, stylish, and perfect for any device.",
  "Upgrade your wardrobe with the StyleBox subscription. Curated fashion delivered to your door.",
  "Transform your meals with the SpiceMaster grinder. Freshly ground spices for delicious flavors.",
  "Stay cozy with the WarmHug blanket. Soft, luxurious, and perfect for any season.",
  "Protect your home with the SecureCam. Smart security with real-time alerts and HD video.",
  "Enhance your beauty routine with the GlowUp kit. Everything you need for a radiant, flawless look.",
  "Stay productive with the FocusMaster desk. Ergonomic design for comfort and efficiency.",
  "Transform your photos with the PhotoPro editor. Easy-to-use tools for stunning results.",
  "Boost your energy with the VitalFuel drink. Natural ingredients for a healthy, sustained boost.",
  "Stay stylish with the TrendSetter app. Discover the latest fashion trends and shop your favorites.",
  "Protect your phone with the ShockGuard case. Durable, stylish, and built to last.",
  "Stay cool with the BreezePro fan. Powerful, portable, and perfect for any space.",
  "Transform your living room with the DecoLights. Customizable LED lighting for a vibrant atmosphere.",
  "Boost your confidence with the PerfectSmile kit. Whiter teeth and a brighter smile in just minutes.",
  "Stay connected with the ChatMate app. Instant messaging, video calls, and more in one place.",
  "Enhance your gaming experience with the GamerPro chair. Ergonomic design for ultimate comfort.",
  "Boost your health with the VitaBlend smoothie mix. Delicious, nutritious, and easy to make.",
  "Stay hydrated with the PureFlow water filter. Fresh, clean water straight from your tap.",
  "Transform your space with the InstaDecor app. Visualize new furniture and decor with ease.",
  "Stay stylish with the TrendAlert subscription. Curated fashion and accessories delivered monthly.",
  "Protect your car with the AutoGuard dash cam. High-definition video for peace of mind on the road.",
  "Boost your productivity with the WorkSmart app. Organize tasks, set goals, and track progress.",
  "Stay fresh all day with the CoolMist body spray. Long-lasting fragrance and powerful protection.",
  "Transform your hair with the SilkSmooth straightener. Sleek, shiny hair with minimal effort.",
  "Enhance your tech with the SmartHub. Connect and control all your devices from one place.",
  "Stay fit with the FlexiBand resistance bands. Versatile, durable, and perfect for any workout.",
  "Protect your skin with the SunBlock sunscreen. Broad-spectrum protection for all-day defense.",
  "Transform your bedroom with the CozySleep bedding. Soft, luxurious, and perfect for a restful night.",
  "Stay organized with the SmartPlanner. Plan, prioritize, and achieve your goals with ease.",
  "Enhance your workouts with the PowerPro dumbbells. Adjustable weights for a versatile workout.",
  "Protect your home with the HomeShield security system. Smart technology for complete peace of mind.",
];

export const SPEECH_SOCIAL_VIDEOS = [
  "Hey guys, today we're gonna test if Mentos really explode in Coke! Let's see what happens.",
  "This makeup hack will change your life! Use a spoon to get the perfect winged eyeliner every time.",
  "Watch me turn this $5 thrift store find into a designer-looking outfit. DIY magic incoming!",
  "Alright fitness fam, here's a 30-second ab workout that'll leave you sore tomorrow. Let's crush it!",
  "You won't believe what I found in my attic! This antique might be worth thousands.",
  "Time for another episode of 'Will It Blend?' Today's victim: a brand new smartphone!",
  "Cat owners, this litter box trick will save you hours of cleaning every week. Thank me later!",
  "I tried eating like a Victorian for 24 hours and here's what happened to my body.",
  "This is why you should never, ever microwave a grape. The science behind it is mind-blowing!",
  "Watch me parallel park this car blindfolded. Don't try this at home, folks!",
  "I'm about to reveal the secret ingredient that makes these cookies irresistible. Bakers, take notes!",
  "I'm testing this viral TikTok hack for cleaning white shoes. Does it actually work?",
  "Watch this egg survive a 100ft drop with just materials from your kitchen. Physics is wild!",
  "I'm about to eat the world's hottest pepper. Wish me luck... I might regret this.",
  "This is how you can turn an old pair of jeans into a trendy crop top. No sewing required!",
  "I'm pranking my roommate by covering their entire room in tin foil. Their reaction is priceless!",
  "Here's a life hack for keeping your avocados fresh for weeks. Say goodbye to brown guacamole!",
  "Watch me recreate this famous painting using only condiments. Art has never been so tasty!",
  "I'm testing this viral hack for falling asleep in 2 minutes. Does it actually work?",
  "Here's why you should never use hot water to defrost your windshield. Mind-blowing science ahead!",
  "Watch this banana turn into 'nice cream' in seconds. Vegans, you're gonna love this!",
  "I'm about to reveal the secret to getting free food at any restaurant. Don't tell the managers!",
  "This is how you can turn a plain white tee into a tie-dye masterpiece using just markers.",
  "I'm testing if these 'unbreakable' plates really are unbreakable. You won't believe what happens!",
  "Here's a 30-second trick to make your flowers last twice as long. Florists hate this hack!",
  "Watch me turn this cardboard box into a functional guitar. DIY musicians, this one's for you!",
  "This is why you should never put metal in the microwave. The results are explosive!",
  "Here's how to fold a t-shirt in under 2 seconds. Your laundry day will never be the same!",
  "Watch this ordinary pencil transform into a mini crossbow. Office supplies just got dangerous!",
  "I'm testing if these waterproof shoes really are waterproof. Time for an extreme puddle jump!",
  "Here's a mind-blowing trick to separate egg yolks using just a water bottle. Bakers, take note!",
  "Watch me turn this dollar store frame into vintage decor. Budget decorating at its finest!",
  "I'm about to reveal the secret to getting perfectly crispy bacon every time. Breakfast game changer!",
  "This is how you can open a wine bottle without a corkscrew. Party trick of the century!",
  "Here's why you should never use your phone while it's charging. The science is shocking!",
  "Watch this ordinary banana peel clean my leather shoes. Who knew fruit could be so useful?",
  "Here's a 5-second hack to keep your headphones from tangling. Say goodbye to knots forever!",
  "Watch me turn this plastic bottle into a fully functional sprinkler. Summer just got cooler!",
  "I'm about to show you how to cut an onion without crying. Your kitchen will never be the same!",
  "This is why you should never put ice in your drink at restaurants. The truth will shock you!",
  "Here's how to fold a paper airplane that can fly over 100 feet. Physics class just got interesting!",
  "Watch this ordinary rubber band fix a stripped screw. DIY enthusiasts, you're welcome!",
  "I'm testing if these 'indestructible' socks really are indestructible. Time for an extreme workout!",
  "Here's a mind-blowing trick to ripen an avocado in just 10 minutes. Guacamole emergency solved!",
  "Watch me turn this old ladder into a bookshelf. Upcycling has never looked so good!",
  "This is how you can charge your phone with a lemon. Science experiment or survival skill?",
  "Here's why you should never wash chicken before cooking it. Food safety 101, folks!",
  "Watch this ordinary fork become a perfect egg separator. Baking just got a whole lot easier!",
  "I'm testing if these 'stain-proof' clothes really are stain-proof. Time for a messy experiment!",
  "Here's a 3-second trick to tell if your eggs are still good. No more guessing games!",
  "Watch me turn this old tire into a gorgeous planter. Gardening meets recycling!",
  "I'm about to show you how to fold a fitted sheet in under 60 seconds. Laundry day revolution!",
  "This is why you should never use a glass of water to put out a grease fire. Kitchen safety 101!",
  "Watch this ordinary clothes hanger become a tablet stand. DIY tech accessories for the win!",
  "I'm testing if these 'unbreakable' sunglasses really are unbreakable. Time for some extreme sports!",
  "Here's a mind-blowing trick to peel a mango in seconds. Fruit ninja skills unlocked!",
  "Watch me turn this old pallet into a rustic coffee table. Farmhouse chic on a budget!",
  "This is how you can use a walnut to fix scratches on wood furniture. DIY restoration made easy!",
  "Here's why you should never put tomatoes in the fridge. The truth about produce storage revealed!",
  "Watch this ordinary paper clip become a universal phone stand. Tech hack of the century!",
  "I'm testing if these 'fireproof' gloves really are fireproof. Don't try this at home, folks!",
  "Here's a 10-second trick to keep your guacamole from turning brown. Avocado lovers rejoice!",
  "Watch me turn this old book into a secret storage box. Perfect for hiding your snacks!",
  "I'm about to show you how to fold a shirt in under 2 seconds. Laundry day will never be the same!",
  "This is why you should never use hot water to make clear ice cubes. The science will blow your mind!",
  "Here's how to make a phone speaker out of a toilet paper roll. DIY audio enhancement!",
  "Watch this ordinary rubber band become a jar opener. Kitchen struggles solved!",
  "Here's a mind-blowing trick to cut a watermelon in under 60 seconds. Summer parties just got easier!",
  "Watch me turn this old drawer into a floating shelf. Upcycling for the modern home!",
  "I'm about to reveal the secret to getting wrinkles out of clothes without an iron. Lazy person hack!",
  "This is how you can use a potato to remove a broken lightbulb. Electrical DIY made safe!",
  "Here's why you should never rinse pasta after cooking it. Italian grandmas were right all along!",
  "Watch this ordinary dental floss become a cake slicer. Perfect portions every time!",
  "I'm testing if these 'odor-proof' socks really are odor-proof. Time for a week-long challenge!",
  "Here's a 5-second trick to keep your bananas fresh for weeks. No more brown fruit!",
  "Watch me turn this old ladder into a towel rack. Bathroom storage never looked so cool!",
  "I'm about to show you how to fold a pizza box into a plate. Dorm room dining hack!",
  "This is why you should never use boiling water to make coffee. The perfect brew revealed!",
  "Here's how to make a DIY phone projector with a shoebox. Movie night just got upgraded!",
  "Watch this ordinary binder clip become a cord organizer. Desk clutter, begone!",
  "I'm testing if these 'shatterproof' wine glasses really are shatterproof. Cheers to science!",
  "Here's a mind-blowing trick to hull strawberries in seconds. Fruit prep made easy!",
  "Watch me turn this old suitcase into a pet bed. Travel-themed decor for your furry friend!",
  "I'm about to reveal the secret to getting perfectly crispy tofu every time. Vegans, you're welcome!",
  "This is how you can use a tennis ball to open a tight jar lid. Kitchen struggles solved!",
  "Here's why you should never store onions and potatoes together. The produce truth you need to know!",
  "Watch this ordinary clothespin become a smartphone stand. DIY tech accessories for pennies!",
  "Watch me turn this old window into a picture frame. Vintage charm meets modern decor!",
  "I'm about to show you how to fold a dollar bill into a ring. Origami meets fashion!",
  "This is why you should never put oil in pasta water. Italian cooking myths debunked!",
];

export type VoiceOptionType = {
  label: string;
  tags: string[];
  description: string;
  url: string;
};

export const VOICES: VoiceOptionType[] = [
  {
    label: "Michael - Energetic announcer",
    description:
      "Michael's voice is clear and authoritative, perfect for narrating history documentaries. Its steady and engaging tone brings historical events to life, making them both informative and captivating for listeners.",
    tags: ["male", "american", "announcer"],
    url: "https://deeptune-studio.s3.amazonaws.com/voices/c8bab81d-24d7-45f2-873d-64d959bdbe86.wav",
  },
  {
    label: "Ashley - Professional and confident",
    description:
      "Ashley voice is warm and confident, ideal for professional settings and audiobooks. She is smooth and persuasive, making her a natural leader and communicator.",
    tags: ["female", "british", "energetic"],
    url: "https://deeptune-studio.s3.amazonaws.com/voices/7c260735-a9ad-4b5b-b0c0-c78c840eb766.mp3",
  },
  {
    label: "Samuel - Old but full of character",
    tags: ["male", "british", "deep"],
    description:
      "Samuel Harris' voice is gravelly and full of character, perfect for bringing older, seasoned characters to life. Its rich, textured tones add depth and authenticity, making every performance memorable.",
    url: "https://deeptune-voices.s3.amazonaws.com/polle/inference/0452d62b-3396-426c-b1c1-7bec8f14cf51.wav",
  },
];
