import styled from "styled-components";
import { Theme, Button, Slider } from "@radix-ui/themes";
import { useState } from "react";
import { CheckIcon } from "@radix-ui/react-icons";

const URL = "https://form.typeform.com/to/B8cNtuo1";

export const Pricing = () => {
  const [creator, setCreator] = useState([0]);
  const [pro, setPro] = useState([0]);

  const creatorMins = (creator[0] / 50) * 15 + 30;
  const proMins = (pro[0] / 25) * 120 + 120;

  return (
    <Theme appearance="dark" style={{ height: "100%" }}>
      <Container>
        <CardGrid>
          <Card>
            <div>
              <div>
                <Plan>Creator</Plan>
                <Title>${4 * creatorMins} / mo</Title>
                <Subtitle>{creatorMins} minutes per month</Subtitle>
                <Slider
                  value={creator}
                  onValueChange={setCreator}
                  defaultValue={[0]}
                  step={50}
                  style={{ padding: "16px 0px" }}
                />
              </div>
              <FlexCol style={{ gap: 4, paddingTop: 16 }}>
                {[
                  "$4 / minute",
                  "Full editor access",
                  "Unlimited voices",
                  "Emotion prompting",
                  "1 team member",
                  "Fast video processing",
                ].map((text) => (
                  <Text>
                    <CheckIcon style={{ marginRight: 8 }} />
                    {text}
                  </Text>
                ))}
              </FlexCol>
            </div>
            <Button
              style={{ height: 40, cursor: "pointer" }}
              onClick={() => window.open(URL, "_blank")}
            >
              Start Free Trial
            </Button>
          </Card>
          <Card>
            <div>
              <div>
                <Plan>Professional</Plan>
                <Title>${3 * proMins} / mo</Title>
                <Subtitle>{proMins / 60} hours per month</Subtitle>
                <Slider
                  value={pro}
                  onValueChange={setPro}
                  defaultValue={[0]}
                  step={25}
                  style={{ padding: "16px 0px" }}
                />
              </div>
              <FlexCol style={{ gap: 4, paddingTop: 16 }}>
                {[
                  "$3 / minute",
                  "Everything in Creator",
                  "Lip sync (coming soon)",
                  "Integrations (eg Protools)",
                  "3 team members",
                  "Faster video processing",
                ].map((text) => (
                  <Text>
                    <CheckIcon style={{ marginRight: 8 }} />
                    {text}
                  </Text>
                ))}
              </FlexCol>
            </div>
            <Button
              style={{ height: 40, cursor: "pointer" }}
              onClick={() => window.open(URL, "_blank")}
            >
              Start Free Trial
            </Button>
          </Card>
          <Card>
            <div>
              <div>
                <Plan>Enterprise</Plan>
                <Title>Contact us</Title>
                <Subtitle>Custom plans</Subtitle>
                <div style={{ height: 40 }} />
              </div>
              <FlexCol style={{ gap: 4, paddingTop: 16 }}>
                {[
                  "Everything in Professional",
                  "Realtime support",
                  "API Access",
                  "SAML/SSO",
                  "Unlimited team members",
                  "Fastest video processing",
                ].map((text) => (
                  <Text>
                    <CheckIcon style={{ marginRight: 8 }} />
                    {text}
                  </Text>
                ))}
              </FlexCol>
            </div>
            <Button
              style={{ height: 40, cursor: "pointer" }}
              color="gray"
              onClick={() =>
                window.open("https://cal.com/deeptim/30min", "_blank")
              }
            >
              Contact Sales
            </Button>
          </Card>
        </CardGrid>
      </Container>
    </Theme>
  );
};

const Container = styled.div`
  /* background: #000000; */
  background: #0d0d0d;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Plan = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 300;
`;

const Title = styled.div`
  color: white;
  font-size: 32px;
  font-weight: 700;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 18px;
  font-weight: 400;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
  padding: 16px;
  max-width: 1000px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: #121212;
  /* background: white; */
  flex: 1;
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.div`
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
