import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import {
  IconCheck,
  IconChevronDown,
  IconExternalLink,
} from "@tabler/icons-react";
import styled from "styled-components";
import { VoiceOptionType, VOICES } from "../constants";
import { PopoverContent } from "../../radix/popover";
import { FlexCol, FlexRow, Label, Tag } from "../../shared/ui";

type Props = {
  activeVoice: VoiceOptionType;
  onChange: (voice: VoiceOptionType) => void;
};

export const VoicePopover = ({ activeVoice, onChange }: Props) => {
  return (
    <Popover>
      <StyledPopoverTrigger>
        <ModelRow>
          <FlexRow style={{ justifyContent: "space-between", gap: 8 }}>
            <FlexCol style={{ gap: 4 }}>
              <div style={{ fontWeight: 500 }}>{activeVoice.label}</div>
              <UriLabel style={{ maxWidth: 240 }}>
                {activeVoice.description}
              </UriLabel>
              {/* <FlexRow style={{ gap: 4 }}>
                {activeVoice.tags.map((tag) => (
                  <Tag
                    key={tag}
                    style={{ background: "var(--background-secondary)" }}
                  >
                    {tag}
                  </Tag>
                ))}
              </FlexRow> */}
            </FlexCol>
            <IconChevronDown style={{ width: 16, height: 16 }} />
          </FlexRow>
        </ModelRow>
      </StyledPopoverTrigger>
      <StyledPopoverContent collisionPadding={8} sideOffset={8}>
        <FlexCol>
          {VOICES.map((voice) => (
            <ModelRow
              key={voice.url}
              onClick={() => {
                onChange(voice);
              }}
              style={{ border: "none" }}
            >
              <FlexRow style={{ justifyContent: "space-between" }}>
                <FlexCol style={{ gap: 4, width: "calc(100% - 32px)" }}>
                  <div style={{ fontWeight: 500, fontSize: 14 }}>
                    {voice.label}
                  </div>
                  <UriLabel style={{ fontSize: 13 }}>
                    {voice.description}
                  </UriLabel>
                  <FlexRow style={{ gap: 4 }}>
                    {voice.tags.map((tag) => (
                      <StyledTag key={tag}>{tag}</StyledTag>
                    ))}
                  </FlexRow>
                </FlexCol>
                {voice.url === activeVoice.url ? (
                  <IconCheck style={{ width: 18, height: 18 }} />
                ) : null}
              </FlexRow>
            </ModelRow>
          ))}
          {/* <ModelRow
            onClick={() => {
              window.open("https://app.deeptune.com", "_blank");
            }}
            style={{ border: "none" }}
          >
            <FlexRow style={{ justifyContent: "space-between" }}>
              <FlexCol style={{ gap: 4, width: "calc(100% - 32px)" }}>
                <div style={{ fontWeight: 500, fontSize: 14 }}>
                  View all voices
                </div>
                <UriLabel style={{ fontSize: 13 }}>
                  Hundreds of royalty-free voices in the library
                </UriLabel>
              </FlexCol>
              <IconExternalLink style={{ width: 18, height: 18 }} />
            </FlexRow>
          </ModelRow> */}
        </FlexCol>
      </StyledPopoverContent>
    </Popover>
  );
};

const StyledPopoverTrigger = styled(PopoverTrigger)`
  all: unset;
  width: 100%;
  max-width: 420px;

  @media (max-width: 768px) {
    max-width: none;
  }
`;

const StyledPopoverContent = styled(PopoverContent)`
  padding: 4px;
  max-width: 420px;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: calc(100vw - 16px);
    max-width: none;
  }
`;

const ModelRow = styled.div`
  font-size: 15px;
  padding: 12px 8px;
  cursor: pointer;
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 12px 16px;
  box-sizing: border-box;

  &:hover {
    background: var(--hover);
  }

  @media (max-width: 768px) {
    font-size: 13px;
    align-items: stretch;
    gap: 8px;
  }
`;

const UriLabel = styled(Label)`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  /* max-width: 260px; */
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledTag = styled(Tag)`
  font-size: 12px;
  background: var(--background-secondary);

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;
