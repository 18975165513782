import styled, { keyframes } from "styled-components";

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input`
  all: unset;
  font-size: 13px;
  height: 34px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--text-primary);
  background: var(--background-secondary);
  border: 1px solid var(--border);
  transition: all 0.2s ease-in-out;

  &:disabled {
    color: var(--text-light);
    cursor: not-allowed;
  }
`;

export const Textarea = styled.textarea`
  all: unset;
  font-size: 13px;
  height: 34px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--text-primary);
  background: var(--background-secondary);
  border: 1px solid var(--border);

  &:disabled {
    color: var(--text-light);
    cursor: not-allowed;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;

export const Button = styled.button`
  all: unset;
  cursor: pointer;
  background: linear-gradient(#4437ca, #3d31b5);
  border: 1px solid var(--border);
  padding: 8px;
  font-size: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  color: white;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  gap: 8px;
  box-shadow: var(--hover), inset 0px 0px 0px 1px var(--border);

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const ButtonSecondary = styled.button`
  cursor: pointer;
  all: unset;
  border: 1px solid var(--border);
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 6px;
  :disabled {
    opacity: 0.4;
  }
  &:hover {
    background-color: var(--hover);
  }
`;

export const Tag = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  padding: 2px 8px;
  background: ${({ isActive }) => (isActive ? "var(--hover)" : "transparent")};
  border: ${({ isActive }) =>
    isActive ? "1px solid var(--text-light)" : "1px solid var(--border)"};
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  color: var(--text-primary);

  &:hover {
    background: var(--hover);
  }
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--text-primary);
`;

export const ToggleButton = styled.div`
  cursor: pointer;
  color: var(--text-secondary);
  font-size: 11px;
  padding: 4px;
  transition: all 0.2s ease-out;
  border-radius: 4px;
  :hover {
    background: var(--hover);
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--text-primary);
  margin-right: 4px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: var(--hover);
  margin: 12px 0;
  flex: 1;
`;

const shineAnimation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

export const LoadingShine = styled.div`
  background: linear-gradient(
    110deg,
    rgba(198, 203, 222, 0.25) 8%,
    rgba(198, 203, 222, 0.07) 18%,
    rgba(198, 203, 222, 0.25) 33%
  );
  border-radius: 4px;
  background-size: 200% 100%;
  animation: ${shineAnimation} 1.5s linear infinite;
`;

export const Icon = styled.span`
  /* color: var(--text-primary); */
  text-align: center;
`;

export const HoverIcon = styled(Icon)`
  /* transition: all 0.2s ease-out; */
  border-radius: 4px;
  user-select: none;
  &:hover {
    background: var(--hover);
  }
  &:active {
    background: var(--hover);
  }
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
