import React from "react";
import styled from "styled-components";
import Waveform from "./components/waveform";

const AudioScreen = () => {
  return (
    <div style={{ height: "100%", display: "flex" }}>
      <Container>
        <Waveform
          audio="https://deeptune-voices.s3.amazonaws.com/site/deeptune_en.mp3"
          title="Original"
        />
        <Waveform
          audio="https://deeptune-voices.s3.amazonaws.com/site/deeptune_es.mp3"
          title="🇪🇸 Spanish"
        />
        <Waveform
          audio="https://deeptune-voices.s3.amazonaws.com/site/deeptune_hi.mp3"
          title="🇮🇳 Hindi"
        />
        <Waveform
          audio="https://deeptune-voices.s3.amazonaws.com/site/deeptune_jp.mp3"
          title="🇯🇵 Japanese"
        />
      </Container>
    </div>
  );
};

export default AudioScreen;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  padding: 32px;
  background: #1b1c1d;
  font-family: "IBM Plex Sans";
  flex: 1;
`;
