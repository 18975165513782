import "@radix-ui/themes/styles.css";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import GlobeScreen from "./modules/globe";
import Demo from "./modules/demo";
import DemoV2 from "./modules/demo/v2";
import AudioScreen from "./modules/audio";
import { Pricing } from "./modules/pricing";
import { TtsDemo } from "./modules/ttsdemo";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/globe" />} />
        <Route
          path="/demo"
          element={
            <Navigate to="/demos/67881383-3bbe-4b64-879b-44aae0155a0f" />
          }
        />
        <Route path="/demos/:id" element={<Demo />} />
        <Route path="/demos/v2/:id" element={<DemoV2 />} />
        <Route path="/globe" element={<GlobeScreen />} />
        <Route path="/audio" element={<AudioScreen />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/ttsdemo" element={<TtsDemo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
