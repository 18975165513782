import React from "react";
import { TailSpin } from "react-loader-spinner";
import styled from "styled-components";

export function Loading() {
  return (
    <Container>
      <TailSpin width={36} height={36} color="var(--text-light)" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--background);
`;
