import React, { useEffect, useRef, useState } from "react";
import {
  AmbientLight,
  Color,
  DirectionalLight,
  Fog,
  MeshPhongMaterial,
  PerspectiveCamera,
  PointLight,
} from "three";
import Globe, { GlobeMethods } from "react-globe.gl";
import countries from "./files/globe-data-min.json";
import { useLocation } from "react-router-dom";
import { useCompany } from "../hooks/useCompany";

interface WindowSize {
  width: number;
  height: number;
}

const GlobeScreen = () => {
  const globeEl = useRef<GlobeMethods>();
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { profilePic } = useCompany();
  const imgSrc = queryParams.get("u")?.length
    ? profilePic
    : "https://uploads-ssl.webflow.com/631f727b311d7b88d19e1e81/6477c5d0171f5c364a30f729_unnamed%20(1).png";

  useEffect(() => {
    if (!globeEl.current) return;
    const scene = globeEl.current.scene();
    const controls = globeEl.current.controls();
    controls.autoRotate = true;
    // const camera = globeEl.current.camera();
    // console.log('camera', camera);

    scene.add(new AmbientLight(0xbbbbbb, 0.3));
    // scene.background = new Color(0x0e0f10);

    // Initialize camera, light
    const camera = new PerspectiveCamera();
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    const dLight = new DirectionalLight(0xffffff, 0.8);
    dLight.position.set(-800, 2000, 400);
    camera.add(dLight);

    const dLight1 = new DirectionalLight(0x7982f6, 1);
    dLight1.position.set(-200, 500, 200);
    camera.add(dLight1);

    const dLight2 = new PointLight(0x8566cc, 0.5);
    dLight2.position.set(-200, 500, 200);
    camera.add(dLight2);

    camera.position.z = 400;
    camera.position.x = 0;
    camera.position.y = 0;

    scene.add(camera);

    scene.fog = new Fog(0x535ef3, 400, 2000);

    console.log("camera", camera);
  }, [globeEl, profilePic]);

  const globeMaterial = new MeshPhongMaterial();
  globeMaterial.color = new Color(0x3a228a);
  globeMaterial.emissive = new Color(0x220038);
  globeMaterial.emissiveIntensity = 0.2;
  globeMaterial.shininess = 0.7;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Globe
      ref={globeEl}
      width={windowSize.width}
      height={windowSize.height}
      hexPolygonsData={countries.features}
      hexPolygonResolution={3}
      hexPolygonMargin={0.7}
      showAtmosphere={true}
      atmosphereColor="#3a228a"
      atmosphereAltitude={0.5}
      backgroundColor="rgba(0,0,0,1)"
      hexPolygonColor={(e) => {
        if (
          ["KGZ", "KOR", "THA", "RUS", "UZB", "IDN", "KAZ", "MYS"].includes(
            (e as any).properties.ISO_A3
          )
        ) {
          return "rgba(255,255,255, 1)";
        }
        return "rgba(255,255,255, 0.7)";
      }}
      globeMaterial={globeMaterial}
      htmlElementsData={gData}
      htmlElement={(d: any) => {
        const el = document.createElement("div");
        //
        el.innerHTML = `
            <div style='display: flex; flex-direction: row; gap: 4px;'>
                <img style='height: 18px; width: 18px;' src='${imgSrc}' />
                <div>${d.text}</div>
            </div>
        `;
        el.style.color = "white";
        el.style.fontSize = "14px";
        el.style.fontFamily = "Inter";
        el.style.borderRadius = "4px";
        el.style.padding = "12px";
        el.style.backgroundColor = "rgba(0, 0, 0, 0.2)";
        (el.style as any).backdropFilter = "blur(20px)";
        el.style.display = "flex";
        el.style.flexDirection = "row";
        el.style.justifyContent = "center";
        el.style.alignItems = "center";
        el.style.gap = "4px";
        // (el.style as any)['pointer-events'] = 'auto';
        // el.style.cursor = 'pointer';
        // el.onclick = () => console.info(d);
        return el;
      }}
    />
  );
};

export default GlobeScreen;

const gData = [
  {
    lat: 28.6139,
    lng: 77.209,
    lang: "Hindi", // Delhi
    text: "हिंदी",
  },
  {
    lat: 23.685,
    lng: 90.3563,
    lang: "Bangla", // Bangladesh
    text: "বাংলা",
  },
  {
    lat: 17.385,
    lng: 78.4867,
    text: "తెలుగు", // Hyderabad
  },
  // {
  //   lat: 19.076,
  //   lng: 72.8777,
  //   text: 'Marathi', // Mumbai
  // },
  // {
  //   lat: 13.0827,
  //   lng: 80.2707,
  //   text: 'Tamil', // Chennai
  // },
  {
    lat: -14.235,
    lng: -51.9253,
    lang: "Portuguese",
    text: "Português",
  },
  {
    lat: 12.769,
    lng: -85.6024,
    lang: "Spanish",
    text: "Español",
  },
  {
    lat: 37.0902,
    lng: -95.7129,
    lang: "English",
    text: "English",
  },
  {
    lat: -0.7893,
    lng: 113.9213,
    lang: "Indonesian",
    text: "Indonesia",
  },
  {
    lat: 36.2048,
    lng: 138.2529,
    lang: "Japanese",
    text: "日本語",
  },
  {
    lat: 30.3753,
    lng: 69.3451,
    lang: "Urdu", // Pakistan
    text: "اردو",
  },
  {
    lat: 51.1657,
    lng: 10.4515,
    lang: "German",
    text: "Deutsch",
  },
  {
    lat: 14.0583,
    lng: 108.2772,
    lang: "Vietnamese",
    text: "Tiếng Việt",
  },
  {
    lat: 38.9637,
    lng: 35.2433,
    lang: "Turkish",
    text: "Türkçe",
  },
  {
    lat: 12.8797,
    lng: 121.774,
    lang: "Filipino",
    text: "Filipino",
  },
  {
    lat: 46.2276,
    lng: 2.2137,
    lang: "French",
    text: "Français",
  },
  {
    lat: 35.9078,
    lng: 127.7669,
    lang: "Korean",
    text: "한국인",
  },
  {
    lat: 26.8206,
    lng: 30.8025,
    lang: "Arabic",
    text: "عربي",
  },
  {
    lat: 41.8719,
    lng: 12.5674,
    lang: "Italian",
    text: "Italiano",
  },
  {
    lat: 15.87,
    lng: 100.9925,
    lang: "Thai",
    text: "แบบไทย",
  },
  {
    lat: 61.524,
    lng: 105.3188,
    lang: "Russian",
    text: "Русский",
  },
  {
    lat: 35.8617,
    lng: 104.1954,
    lang: "Chinese",
    text: "中文",
  },
  {
    lat: -0.0236,
    lng: 37.9062,
    lang: "Swahali",
    text: "Kiswahili",
  },
];
