import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useCompany = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get("u");
  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState("acmecorp");
  const [name, setName] = useState("Acme Corp");
  const [followers, setFollowers] = useState(1200000);
  const [profilePic, setProfilePic] = useState(
    "https://deeptune-voices.s3.amazonaws.com/acmelogo.png"
  );

  const handleLoad = async () => {
    try {
      if (!user?.length) return;
      setLoading(true);
      const { data } = await axios.get(
        `https://deeptune-voices.s3.amazonaws.com/insta/${user}/${user}.json`
      );
      setProfilePic(data.profile_pic_url);
      setUsername(data.username);
      setName(data.full_name);
      setFollowers(data.followers);
      console.log(data);
    } catch {
      console.log("could not find user");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLoad();
  }, [user]);

  return { name, profilePic, username, isLoading, followers };
};
