import styled from "styled-components";
import { useState, useRef } from "react";
import axios from "axios";
import {
  IconPlayerPlayFilled,
  IconPlayerPauseFilled,
} from "@tabler/icons-react";
import { TailSpin } from "react-loader-spinner";
import { Button, FlexCol, FlexRow } from "../shared/ui";
import {
  VoiceOptionType,
  VOICES,
  CHARACTER_LIMIT,
  SPEECH_ADS,
  SPEECH_FUN_FACTS,
  SPEECH_SOCIAL_VIDEOS,
  WEBSERVER_URL,
} from "./constants";
import {
  IconBrain,
  IconDeviceMobile,
  IconSpeakerphone,
} from "@tabler/icons-react";
import useAudioPlayer from "./use-audio-player";
import { VoicePopover } from "./components/voice-popover";

export const TtsDemo = () => {
  const [selectedVoice, setSelectedVoice] = useState<VoiceOptionType>(
    VOICES[0]
  );
  const [input, setInput] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    audioRef,
    isPlaying,
    progress,
    playAudio,
    pauseAudio,
    setProgress,
    setIsPlaying,
  } = useAudioPlayer();
  const lastGeneratedTextRef = useRef<string>("");
  const lastGeneratedVoiceUrl = useRef<string>("");

  const generateAudio = async (text: string, uri: string) => {
    if (!selectedVoice?.url || !text) return;
    try {
      setLoading(true);
      const { data } = await axios.post(`${WEBSERVER_URL}/speech/demo`, {
        prompt_audio_uri: { url: uri },
        target_text: text,
      });
      if (audioRef.current) {
        audioRef.current.src = data.results[0].url;
        audioRef.current.addEventListener("ended", () => {
          setIsPlaying(false);
          setProgress(0);
        });
        lastGeneratedTextRef.current = text;
        lastGeneratedVoiceUrl.current = uri;
        playAudio();
      }
    } catch (e) {
      console.log("An error occurred:", e);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayPause = () => {
    if (isLoading) return;
    if (
      input !== lastGeneratedTextRef.current ||
      selectedVoice?.url !== lastGeneratedVoiceUrl.current
    ) {
      generateAudio(input, selectedVoice.url);
    } else if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const handleClickPill = (variant: string[]) => {
    const text = variant[Math.floor(Math.random() * variant.length)];
    setInput(text);
    generateAudio(text, selectedVoice.url);
    pauseAudio();
    setProgress(0);
  };

  const handleClickVoice = (v: VoiceOptionType) => {
    setSelectedVoice(v);
    generateAudio(input, v.url);
    pauseAudio();
    setProgress(0);
  };

  return (
    <Container>
      <audio ref={audioRef} />
      <Textarea
        value={input}
        onChange={(e) => {
          if (e.target.value.length <= CHARACTER_LIMIT) {
            setInput(e.target.value);
          }
        }}
        placeholder="Type anything and turn it into natural, human-like speech..."
      />
      <PillContainer>
        <Pill
          onClick={() => {
            handleClickPill(SPEECH_FUN_FACTS);
          }}
        >
          <IconBrain
            width={16}
            height={16}
            style={{ color: "var(--text-light)" }}
          />
          <div>Tell me a fun fact</div>
        </Pill>
        <Pill
          onClick={() => {
            handleClickPill(SPEECH_ADS);
          }}
        >
          <IconSpeakerphone
            width={16}
            height={16}
            style={{ color: "var(--text-light)" }}
          />
          <div>Voice an ad</div>
        </Pill>
        <Pill
          onClick={() => {
            handleClickPill(SPEECH_SOCIAL_VIDEOS);
          }}
        >
          <IconDeviceMobile
            width={16}
            height={16}
            style={{ color: "var(--text-light)" }}
          />
          <div>Try a social media video</div>
        </Pill>
      </PillContainer>
      <ProgressBar>
        <Progress
          style={{
            width: `${Math.max(Math.min(progress, 100), 0).toFixed(3)}%`,
          }}
        />
      </ProgressBar>
      <BottomContainer>
        <div style={{ flex: 1 }}>
          <VoicePopover
            activeVoice={selectedVoice}
            onChange={handleClickVoice}
          />
        </div>
        <FlexRow style={{ marginLeft: "auto", alignItems: "center", gap: 16 }}>
          <CharacterCount>
            {input.length} / {CHARACTER_LIMIT}
          </CharacterCount>
          <StyledButton
            onClick={handlePlayPause}
            disabled={
              !(input.length > 0 && selectedVoice !== null) || isLoading
            }
          >
            <FlexRow
              style={{
                gap: 12,
                transition: "width 0.2s ease-out",
              }}
            >
              {isLoading ? (
                <TailSpin width={18} height={18} color="white" />
              ) : isPlaying ? (
                <IconPlayerPauseFilled style={{ width: 24, height: 24 }} />
              ) : (
                <IconPlayerPlayFilled style={{ width: 24, height: 24 }} />
              )}
            </FlexRow>
          </StyledButton>
        </FlexRow>
      </BottomContainer>
    </Container>
  );
};

const Container = styled(FlexCol)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
  align-items: center;
`;

const Pill = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  transition: all 0.1s ease-out;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;

  &:hover {
    border: 1px solid var(--text-light);
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.99);
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 12px;
    width: 100%;
  }
`;

const PillContainer = styled(FlexRow)`
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
`;

const BottomContainer = styled(FlexRow)`
  gap: 16px;
  align-items: right;
  margin-top: 0px;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
`;

const Textarea = styled.textarea`
  all: unset;
  font-size: 18px;
  flex-grow: 1;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  background: white;
  position: relative;
  z-index: 1;

  &:disabled {
    color: var(--text-light);
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  padding: 12px;
  margin: 12px;
  border-radius: 50%;
  background: black;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  transition: all 0.1s ease-out;

  &:disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  margin: 8px 0;
  border-radius: 2px;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  background-color: black;
`;

const CharacterCount = styled.div`
  font-size: 14px;
  color: var(--text-light);
  text-align: right;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
